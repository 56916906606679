import React, { useContext } from "react"
import Map from "./Map"
import { useStaticQuery, graphql } from "gatsby"
import SiteConfig from "../../context/SiteConfig"

const ProjectsMap = ({ activeProject }) => {
  const { xl, activeLanguage } = useContext(SiteConfig)

  const {
    title,
    projects,
    link,
    periodTitle,
    locationTitle,
    clientTitle,
    ourRolesTitle,
  } = useStaticQuery(graphql`
    {
      strapiMap {
        title {
          is
          en
        }
        periodTitle {
          is
          en
        }
        locationTitle {
          is
          en
        }
        clientTitle {
          is
          en
        }
        ourRolesTitle {
          is
          en
        }
        projects {
          slug
          card {
            alt {
              en
              is
            }
            src {
              formats {
                small {
                  url
                }
              }
              url
            }
          }
          text {
            is
            en
          }
          name {
            en
            is
          }
          location {
            is
            en
          }
          period
          client {
            is
            en
          }
          ourRoles {
            is
            en
          }
          coordinates {
            latitude
            longitude
          }
          isLink
        }
        link {
          title {
            en
            is
          }
          to
        }
      }
    }
  `).strapiMap

  return (
    <div className="relative mt-12 bg-neutrals-00">
      <div className="absolute  top-6 left-0 right-0 container px-4 mx-auto md:px-6 lg:px-10 xl:px-12 xxl:px-20  z-20 inline-flex">
        <div className="h-full flex items-end pb-6 lg:pb-8">
          <div className="pl-4 py-4 pr-6 bg-neutrals-800 border-l-8 border-primary-700 shadow-md rounded">
            <h1 className="text-xl xl:text-3xl text-neutrals-200 select-none">
              {title[activeLanguage]}
            </h1>
          </div>
        </div>
      </div>
      <Map
        projects={projects}
        activeProject={activeProject}
        link={link}
        projectPopUpTitles={{
          periodTitle,
          locationTitle,
          clientTitle,
          ourRolesTitle,
        }}
      ></Map>
    </div>
  )
}

export default ProjectsMap
