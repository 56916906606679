import React from "react"
import DOMPurify from "dompurify"

interface IPolicie {
  id: string
  name: string
  policie: string
}

interface IPolicieProps {
  policie: IPolicie
}

const Policie = ({ policie }: IPolicieProps | null) => {
  if (!policie) {
    return <div></div>
  }

  return (
    <div key={policie.id} className="flex rounded-lg shadow-lg bg-white self-start flex-1">
      <div className="flex-none w-1 bg-primary-800 my-4 rounded-full" />

      <article className="p-6" style={{ paddingLeft: "23px" }}>
        <header className="mb-2">
          <h2 className=" font-medium text-2xl text-neutrals-800 border-neutrals-200 pb-2">{policie.name}</h2>
        </header>

        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(policie.policie)
          }}
          className="markdown"
        />
      </article>
    </div>
  )
}

export default Policie

export { IPolicie }
