import React from "react"
import moment from "moment"

import DOMPurify from "dompurify"

const AvailableJob = ({
  availableJob: { title, published_at, content },
  publishedTimeTitle,
}) => {
  return (
    <article className=" relative bg-white p-4 rounded-lg shadow-md lg:p-6 border-l-8 border-primary-800 max-w-4xl m-auto mb-6 xl:mb-12 last:mb-0 xl:last:mb-0">
      <div>
        <h2 className="text-2xl text-neutrals-1000">{title}</h2>
        <dl className="inline-flex gap-1 text-neutrals-800 mb-4">
          <dt>{publishedTimeTitle}</dt>
          &#8226;
          <dt>{published_at}</dt>
        </dl>
        <div
          className="markdown"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
        ></div>
      </div>
    </article>
  )
}

export default AvailableJob
