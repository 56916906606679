import React, { useRef, useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import DOMPurify from "dompurify"

import PoliciesAccordion from "./PoliciesAccordion"
import Policie, { IPolicie } from "./Policie"
import PoliciesMenu from "./PoliciesMenu"
import SiteConfigContext from "../../context/SiteConfig"

const getFirstPolicie = (policies: [IPolicie]) => (policies.length > 0 ? policies[0].id : null)

const Policies = ({ policies }) => {
  const { lg } = useContext(SiteConfigContext)

  const [activePolicie, setActivePolicie] = useState(lg ? getFirstPolicie(policies) : null)

  const changeActivePolicie = (newPolicieId: string): void => {
    setActivePolicie(newPolicieId)
  }

  useEffect(() => {
    if (activePolicie) return

    setActivePolicie(lg ? getFirstPolicie(policies) : null)
  }, [lg])

  return (
    <>
      {lg ? (
        <div className="container px-4 my-6 lg:my-16 md:px-6 lg:px-10 xl:px-12 mx-auto xxl:px-20">
          <div className="flex gap-12">
            <Policie policie={policies.find(({ id }) => id == activePolicie)} />
            <PoliciesMenu policies={policies} activePolicie={activePolicie} setActivePolicie={changeActivePolicie} />
          </div>
        </div>
      ) : (
        <div>
          {policies.map(policie => (
            <PoliciesAccordion
              key={policie.id}
              policie={policie}
              isOpen={policie.id == activePolicie}
              setActivePolicie={changeActivePolicie}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default Policies
