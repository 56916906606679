import React, { useContext } from "react"
import { Link } from "gatsby"

import Image from "../Image"
import SiteConfigContext from "../../context/SiteConfig"

const ImagesLinks = ({ imagesLinks }) => {
  const { activeLanguage } = useContext(SiteConfigContext)

  return (
    <div className="container px-4 my-6 md:my-12 md:px-6 lg:px-10 lg:my-16 xl:px-12 mx-auto xxl:px-20">
      <section className="grid grid-cols-1 lg:grid-cols-3 gap-6 xl:gap-10">
        {imagesLinks.map(imageLink => (
          <Link
            key={imageLink.link.title}
            to={`${activeLanguage === "is" ? "" : `/${activeLanguage}`}${
              imageLink.link.to
            }`}
          >
            <article className="relative cursor-pointer ">
              <Image
                maxWidth={470}
                className="w-full object-cover object-top shadow-md rounded-md h-60 md:h-96"
                src={imageLink.image.src}
                alt={imageLink.image.alt}
              ></Image>

              <div
                className="absolute inset-0 rounded-md"
                style={{ backgroundColor: "rgba(1,77,64, 0.3)" }}
              >
                <div className="h-full p-6 flex items-end">
                  <div className="p-4 bg-neutrals-800 rounded-md shadow-md border-l-8 border-primary-800">
                    <h4 className=" text-neutrals-200  text-lg select-none ">
                      {imageLink.link.title}
                    </h4>
                  </div>
                </div>
              </div>

              <div
                className="absolute inset-0 opacity-0 hover:opacity-100 rounded-md"
                style={{ backgroundColor: "rgba(1,77,64, 0.5)" }}
              >
                <div className="h-full flex items-center justify-center">
                  <div className="m-4 p-4 rounded-md shadow bg-neutrals-800 border-2 border-neutrals-800 text-neutrals-100 text-lg">
                    {activeLanguage == "is" ? "Skoða nánar" : "See more"}
                  </div>
                </div>
              </div>
            </article>
          </Link>
        ))}
      </section>
    </div>
  )
}

export default ImagesLinks
