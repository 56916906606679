import React, { useState, useContext } from "react"
import DOMPurify from "dompurify"
import { motion, AnimatePresence } from "framer-motion"

import SiteConfigContext from "../../context/SiteConfig"
import Icons, { Icon } from "../Icons"
import { IService } from "./Services"

interface PropsAcccordionHeader {
  service: IService
  isOpen: boolean
  toggleAccordion: () => void
}

interface PropsAccordionData {
  data: string
}

const AcccordionHeader = ({ service, isOpen, toggleAccordion }: PropsAcccordionHeader) => {
  const onClickAccordion = (event: React.MouseEvent<Element, MouseEvent>) => {
    toggleAccordion()
  }

  return (
    <header
      onClick={onClickAccordion}
      className={`flex justify-between items-center p-4 bg-white cursor-pointer ${
        isOpen ? "rounded-t-lg" : "rounded-lg"
      }`}
    >
      <h2 className="text-xl font-medium text-neutrals-800 select-none">{service.name}</h2>
      <a>
        <Icons className="w-5 h-5 text-neutrals-800 fill-current" icon={isOpen ? Icon.arrowUp : Icon.arrowDown} />
      </a>
    </header>
  )
}

const AccordionData = ({ data }: PropsAccordionData) => {
  return (
    <motion.div
      // Start animation
      key="content"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        open: { opacity: 1, height: "auto", paddingBottom: "0.1px" },
        collapsed: { opacity: 0, height: 0 }
      }}
      transition={{ duration: 0.2 }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(data)
        }}
        className="markdown bg-white p-4 mb-3 rounded-b-lg shadow-inner text-neutrals-800 overflow-y-auto"
        style={{
          maxHeight: "75vh",
          height: "100%"
        }}
      />
    </motion.div>
  )
}

interface IPropsServiceAccordion {
  service: IService
  activeAccordion: string // Is a accordion is active, then there could only be one accordion open at a time.
}

const ServiceAccordion = ({ service, activeAccordion }: IPropsServiceAccordion) => {
  const [isOpen, setIsOpen] = useState(null)

  const toggleAccordion = (): void => {
    setIsOpen(!isOpen)
  }

  return (
    <article className="my-12 mx-4 md:mx-6 rounded-lg shadow-lg bg-white">
      <AcccordionHeader service={service} isOpen={isOpen} toggleAccordion={toggleAccordion} />

      <AnimatePresence initial={false}>{isOpen && <AccordionData data={service.text} />}</AnimatePresence>
    </article>
  )
}

interface IPropsServicesAccordion {
  data: [IService]
  oneOpenAtTime: boolean
}

const ServicesAccordion = ({ data, oneOpenAtTime }: IPropsServicesAccordion) => {
  return (
    <div>
      {data.map((service, index) => (
        <ServiceAccordion key={`${index}-${service.name}`} service={service} index={index} />
      ))}
    </div>
  )
}

export default ServicesAccordion
