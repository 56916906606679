import React, { useState, useContext } from "react"
import { Link } from "gatsby"

import Image from "../Image"
import Icons, { Icon } from "../Icons"
import SiteConfig from "../../context/SiteConfig"

const ProjectCard = ({ project }) => {
  const { activeLanguage } = useContext(SiteConfig)
  return (
    <div className=" relative max-w-md m-auto pb-14 w-full lg:my-auto shadow-md">
      <Image
        maxWidth={500}
        className="w-full object-cover rounded-t-md"
        src={project.card.src}
        alt={project.card.alt}
        style={{
          height: "14rem",
        }}
      />
      <Link
        replace={true}
        className=" absolute bottom-0 intset-x-0 w-full border-t-4 border-primary-700 bg-neutrals-800 flex justify-between items-center p-4 rounded-b-md"
        to={`${
          activeLanguage === "is"
            ? `/projects/${project.slug}`
            : `/${activeLanguage}/projects/${project.slug}`
        }`}
      >
        <h3 className="text-lg  md:text-xl text-neutrals-200">
          {project.name}
        </h3>

        <div className="">
          <Icons
            className="w-5 h-5 text-primary-600 fill-current"
            icon={Icon.arrowRight}
          />
        </div>
      </Link>
    </div>
  )
}
export default ProjectCard
