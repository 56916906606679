import React from "react"
import { motion, AnimatePresence } from "framer-motion"

import Icons, { Icon } from "../Icons"
import Accordion from "../Accordion"
import Portal from "../Portal"
import Img from "../Image"

const variants = {
  onEnter: {
    y: "100%",
  },
  visible: {
    y: 0,
  },
  onLeave: {
    y: "100%",
  },
}

const StaffMoreInfoMobileTest = ({ employee, isOpen, onClickClose }) => {
  const { moreInfo } = employee
  return (
    <AnimatePresence key={`animatePresence-${employee.name}`}>
      {isOpen && (
        <Portal>
          <motion.div
            initial="onEnter"
            animate="visible"
            exit="onLeave"
            variants={variants}
            transition={{
              duration: 0.4,
              damping: 200,
            }}
            className=" fixed top-0 left-0 w-full h-full bg-neutrals-100 overflow-y-scroll"
            style={{ zIndex: 60 }}
          >
            <div className=" relative py-10 px-4 h-full">
              <div className="w-48 m-auto mb-8">
                <Img
                  maxWidth={500}
                  className="rounded-lg"
                  src={employee.image.src}
                  alt={employee.image.alt}
                />
              </div>
              <h1 className="text-2xl text-neutrals-900">{employee.name}</h1>
              <div className="mt-4">
                {Object.keys(employee.moreInfo).map(key => {
                  return (
                    moreInfo[key].items.length > 0 && (
                      <Accordion
                        title={moreInfo[key].title}
                        accordionData={moreInfo[key].items}
                      />
                    )
                  )
                })}
              </div>
              <div className="absolute top-0 right-0 p-6">
                <div
                  className="w-6 h-6 text-neutrals-800 fill-current "
                  onClick={onClickClose}
                >
                  <Icons icon={Icon.x} />
                </div>
              </div>
            </div>
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  )
}

export default StaffMoreInfoMobileTest
