import React, { useState, useRef, useEffect, useContext } from "react"

import SiteConfig from "../../context/SiteConfig"
import Image from "../Image"

import getRandomIndex from "../../util/getRandomIndex"

const ImgWrapper = ({ content, infoBoxHeight }) => {
  const { md } = useContext(SiteConfig)

  const [index, setIndex] = useState(0)
  const [device, setDevice] = useState(null)

  useEffect(() => {
    if (md) {
      setDevice("lg")
    } else {
      setDevice("default")
    }
  }, [, innerWidth, md])

  useEffect(() => {
    if (content && content[device]) {
      setIndex(getRandomIndex(content[device].length))
    }
  }, [, device, content])

  return (
    <div className="relative m-auto h-full w-full overflow-hidden">
      {device &&
        index !== null &&
        content[device] &&
        content[device][index] && (
          <>
            {content[device][index].src.ext === ".mp4" ? (
              <video
                style={{}}
                key={content[device][index].src}
                className="object-cover  w-full rounded-lg"
                autoPlay
                muted
                src={`activeContent.src.url`}
                type="video/mp4"
              />
            ) : (
              <Image
                key={content[device][index].src.url}
                className=" md:rounded-lg object-cover w-full h-full overflow-hidden"
                src={content[device][index].src}
                alt={content[device][index].alt}
                maxWidth={2000}
              />
            )}
          </>
        )}
    </div>
  )
}

export default ImgWrapper
