import React, { useState, useEffect, useRef, useContext } from "react"
import { motion, AnimatePresence } from "framer-motion"
import DOMPurify from "dompurify"

import Icons, { Icon } from "../../Icons"
import { Link } from "gatsby"
import SiteConfig from "../../../context/SiteConfig"

const variants = {
  onEnter: {
    opacity: 0.3,
  },
  visible: {
    opacity: 1,
  },
  onLeave: {
    opacity: 0.3,
  },
}

const InfoBox = ({ content, onClick, first, last, numberOfInfoboxes }) => {
  const { lg } = useContext(SiteConfig)

  /* useEffect(() => {
    if (!infoBoxRef.current) return
    setInfoBoxHeight(infoBoxRef.current.clientHeight)

  }, [infoBoxRef]) */

  if (!content) return <div />

  return (
    <div
      className="relative z-20 w-full p-6 bg-neutrals-800  rounded-l-lg md:pr-16  xxl:pr-64 shadow"
      style={{
        boxShadow:
          "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      }}
    >
      <motion.div
        key={content.title}
        variants={variants}
        initial="onEnter"
        animate="visible"
        exit="onLeave"
        transition={{
          duration: 0.7,
          damping: 200,
        }}
        className=""
      >
        <div className="w-full mb-4 flex justify-between gap-4 ">
          <h3 className=" text-neutrals-100 text-2xl xl:text-3xl ">
            {content.title}
          </h3>
          {numberOfInfoboxes !== 1 && (
            <div className="flex items-center">
              <div
                className="py-1 pl-1 cursor-pointer"
                onClick={() => {
                  if (!first) {
                    onClick(-1)
                  }
                }}
              >
                <Icons
                  className={`w-8 h-8 fill-current mr-4 ${
                    first ? "text-neutrals-500" : "text-neutrals-200"
                  }`}
                  icon={Icon.arrowInCircleLeft}
                />
              </div>
              <div
                className="py-1 pr-1 cursor-pointer"
                onClick={() => {
                  if (!last) {
                    onClick(1)
                  }
                }}
              >
                <Icons
                  className={`w-8 h-8 fill-current ${
                    last ? "text-neutrals-500" : "text-neutrals-200"
                  }`}
                  icon={Icon.arrowInCircleRight}
                />
              </div>
            </div>
          )}
        </div>
        <div
          className="text-neutrals-300  mb-6 pr-4 sm:pr-8 lg:pr-16 xl:pr-32 lg:text-lg xxl:text-xl "
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content.text),
          }}
        />
        {content.link && (
          <div className="flex justify-end">
            <Link
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-neutrals-700 text-lg font-medium text-white hover:bg-neutrals-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto uppercase"
              to={content.link.to}
            >
              <h3 className="">{content.link.title}</h3>
            </Link>
          </div>
        )}
      </motion.div>
    </div>
  )
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

const InfoBoxWrapper = ({ infoBoxes }) => {
  const { activeLanguage } = useContext(SiteConfig)

  const [index, setIndex] = useState(0)
  const [infoBoxData, setInfoBoxData] = useState(null)

  const onClick = direction => {
    const newIndex = index + direction
    if (newIndex > infoBoxes.length - 1) return
    if (newIndex < 0) return

    setIndex(newIndex)
  }

  useEffect(() => {
    shuffleArray(infoBoxes)
    setInfoBoxData(
      infoBoxes
        .filter(info => {
          if (
            info.languages.find(language => language.acronym === activeLanguage)
          ) {
            return info
          }
        })
        .sort((info, info2) => info.priority - info2.priority)
    )
  }, [infoBoxes, activeLanguage])

  if (!infoBoxData) return <div />
  return (
    <InfoBox
      content={infoBoxData[index]}
      onClick={onClick}
      first={index === 0}
      last={index === infoBoxData.length - 1}
      numberOfInfoboxes={infoBoxData.length}
    />
  )
}

export default InfoBoxWrapper
