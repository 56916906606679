import React, { useState, useEffect, useContext, useRef } from "react"
import MapGL, {
  Marker,
  NavigationControl,
  FullscreenControl,
} from "@urbica/react-map-gl"
import Cluster from "@urbica/react-map-gl-cluster"
import "mapbox-gl/dist/mapbox-gl.css"

import SiteConfig from "../../context/SiteConfig"
import ProjectCard from "./ProjectCard"
import Icons, { Icon } from "../Icons"

const isTouchEvent = e => e.originalEvent && "touches" in e.originalEvent
const isTwoFingerTouch = e => e.originalEvent.touches.length >= 2

const Map = ({ projects, activeProject, link, projectPopUpTitles }) => {
  const ClusterRef = useRef(null)
  const MapRef = useRef(null)
  const FullScreenRef = useRef(null)

  const { xl, activeLanguage } = useContext(SiteConfig)
  const [activeIndex, setActiveIndex] = useState(null)

  const [viewport, setViewport] = useState({
    latitude: 66.089364,
    longitude: -9.036831,
    zoom: 3,
  })

  const onClickCluster = (cluster, longitude, latitude) => {
    const { clusterId } = cluster

    const supercluster = ClusterRef.current.getCluster()

    const zoom = supercluster.getClusterExpansionZoom(clusterId)

    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom,
    })
  }

  const onClickExitPopUp = () => setActiveIndex(null)

  useEffect(() => {
    if (!projects) return
    if (activeProject) {
      const index = projects.findIndex(
        project => project.slug === activeProject
      )

      if (index >= 0) {
        setActiveIndex(index)
        /*   setViewport({
          ...viewport,
          zoom: 15,
          latitude: projects[index].coordinates.latitude,
          longitude: projects[index].coordinates.longitude,
        }) */
      }
    }
  }, [, activeProject, projects])

  useEffect(() => {
    if (!MapRef.current) return
    const map = MapRef.current._map

    map.on("dragstart", event => {
      if (FullScreenRef.current && FullScreenRef.current._control._fullscreen) {
        return
      }

      if (isTouchEvent(event) && !isTwoFingerTouch(event)) {
        map.dragPan.disable()
      }
    })

    // Drag events not emited after dragPan disabled, so I use touch event here
    map.on("touchstart", event => {
      if (FullScreenRef.current && FullScreenRef.current._control._fullscreen) {
        return
      }

      if (isTouchEvent(event) && isTwoFingerTouch(event)) {
        map.dragPan.enable()
      }
    })
  }, [, MapRef.current])

  if (!activeLanguage) return <div />

  return (
    <>
    <MapGL
      ref={MapRef}
      style={{
        width: "100%",
        height: xl ? "60vh" : "80vh",
        position: "reletive",
        zIndex: "15",
      }}
      mapStyle="mapbox://styles/mapbox/light-v9"
      accessToken={process.env.GATSBY_MAP_KEY}
      onViewportChange={setViewport}
      maxZoom={15}
      minZoom={2}
      scrollZoom={true}
      /*     dragPan={false} */
      {...viewport}
    >
      <FullscreenControl ref={FullScreenRef} position="top-right" />
      <NavigationControl showCompass showZoom position="bottom-right" />
      <Cluster
        ref={ClusterRef}
        radius={40}
        extent={512}
        nodeSize={64}
        component={cluster => (
          <ClusterMarker onClickCluster={onClickCluster} {...cluster} />
        )}
      >
        {projects.map((project, index) => (
          <Marker
            key={project.name[activeLanguage]}
            offsetLeft={-20}
            offsetTop={-10}
            latitude={project.coordinates.latitude}
            longitude={project.coordinates.longitude}
            captureClick={false}
          >
            {project.slug == "Strendingur" ? (
              <div
                className={` relative text-xl font-bold flex items-center justify-center bg-neutrals-400 text-neutrals-800 shadow-xl  w-10 h-10 rounded-full`}
                onClick={() => {
                  setViewport({
                    ...viewport,
                    zoom: 15,
                    latitude: project.coordinates.latitude,
                    longitude: project.coordinates.longitude,
                  })
                  setActiveIndex(index)
                }}
              >
                <Icons
                  className="w-6 h-6 text-neutrals-700 fill-current"
                  icon={Icon.company}
                />
              </div>
            ) : (
              <div
                className={` relative text-xl font-bold flex items-center justify-center bg-neutrals-400 text-neutrals-800 shadow-xl ${
                  project.isLink
                    ? "w-8 h-8 rounded rotate-45"
                    : "w-8 h-8 rounded-full"
                }`}
                onClick={() => {
                  setViewport({
                    ...viewport,
                    zoom: 15,
                    latitude: project.coordinates.latitude,
                    longitude: project.coordinates.longitude,
                  })
                  setActiveIndex(index)
                }}
              >
                <h2 className="z-10">
                  {project.name[activeLanguage].toLocaleUpperCase()[0]}
                </h2>
              </div>
            )}
          </Marker>
        ))}
      </Cluster>

      
    </MapGL>


    {activeIndex !== null && MapRef.current && (
      <div   
        style={{
          width: "100%",
          height: xl ? "60vh" : "80vh",
          position: "absolute",
        }}
        className="z-30 top-0 left-0"
        onClick={onClickExitPopUp}
      >
        <div className="absolute w-full h-full flex justify-center items-center z-30">
          <ProjectCard
            key={projects[activeIndex]._id}
            project={projects[activeIndex]}
            activeLanguage={activeLanguage}
            link={link}
            onClickExitPopUp={onClickExitPopUp}
            projectPopUpTitles={projectPopUpTitles}
            height={MapRef.current._container.current.clientHeight * 0.7}
            activeProject={activeProject}
          />
        </div>
      </div>
    )}
      

    </>
  )
}

const ClusterMarker = ({
  longitude,
  latitude,
  pointCount,
  onClickCluster,
  ...cluster
}) => {
  const onClick = () => {
    onClickCluster(cluster, longitude, latitude)
  }

  return (
    <Marker longitude={longitude} latitude={latitude}>
      <div
        onClick={onClick}
        className="w-12 h-12 bg-neutrals-400 text-neutrals-800 shadow-xl flex justify-center items-center text-xl font-semibold rounded-full"
      >
        {pointCount}
      </div>
    </Marker>
  )
}

/*

 {projects[activeIndex].card && (
              <div className=" relative">
                <Img
                  className=" w-full object-cover"
                  src={projects[activeIndex].card.src}
                  alt={projects[activeIndex].card.alt[activeLanguage]}
                />
                <div
                  className="absolute top-0 right-0 p-2 cursor-pointer bg-neutrals-200 rounded-full shadow m-2 "
                  onClick={() => setActiveIndex(null)}
                >
                  <Icons
                    className="w-4 h-4 z-10 text-neutrals-900 fill-current"
                    icon={Icon.x}
                  />
                </div>
              </div>
            )}
            <div className="mx-4 mb-4">
              <div
                className={`${
                  !projects[activeIndex].card
                    ? "flex justify-between align-baseline"
                    : ""
                }`}
              >
                <h4 className="text-neutrals-800 text-2xl my-4">
                  {projects[activeIndex].name[activeLanguage]}
                </h4>
                {!projects[activeIndex].card && (
                  <div
                    className=" p-2 cursor-pointer  "
                    onClick={() => setActiveIndex(null)}
                  >
                    <Icons
                      className="w-4 h-4 z-10 text-neutrals-900 fill-current"
                      icon={Icon.x}
                    />
                  </div>
                )}
              </div>

              {projects[activeIndex].text[activeLanguage] && (
                <p className="text-neutrals-700 mb-4">
                  {projects[activeIndex].text[activeLanguage]}
                </p>
              )}

              {projects[activeIndex].isLink && (
                <div className="flex justify-end uppercase">
                  <Link
                    to={`${activeLanguage === "is" ? "" : activeLanguage}/${
                      link.to
                    }/${projects[activeIndex].slug}`}
                  >
                    {link.title[activeLanguage]}
                  </Link>
                </div>
              )}
            </div>
*/

export default Map
