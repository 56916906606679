import React, { useContext, useRef, useEffect, useState } from "react"
import DOMPurify from "dompurify"

import Icons, { Icon } from "../Icons"
import SiteConfig from "../../context/SiteConfig"
import { Link } from "gatsby"

const ProjectDescription = ({
  client,
  description,
  location,
  name,
  ourRoles,
  period,
}) => {
  const { lg, activeLanguage, xl } = useContext(SiteConfig)

  return (
    <div className="relative ">
      <section className="grid grid-cols-1 xl:grid-cols-3 xxl:grid-cols-4">
        <div className=" relative   mb-6 xl:mb-0 xl:mr-4 xl:col-span-2 xxl:col-span-3">
          <div className="bg-neutrals-100 p-8 rounded-lg shadow-md">
            <h3 className="text-2xl text-neutrals-800 mb-6 xxl:text-3xl">
              {description.title}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description.text),
              }}
              className="text-lg text-neutrals-700 mb-4 xxl:text-xl"
            ></div>
          </div>
        </div>
        <div className="bg-neutrals-900  p-8  shadow-md rounded-lg">
          <h4 className="text-2xl text-neutrals-300 mb-6 uppercase">
            {ourRoles.title}
          </h4>
          <ul className="pl-4">
            {ourRoles.ourRoles.map(service => (
              <li className="mb-4" key={service.title}>
                <h5 className="text-xl text-neutrals-400 cursor-default">
                  {service}
                </h5>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="mb-16 mt-16 lg:mt-32">
        <div className="lg:grid grid-cols-3 justify-around lg:px-16 xl:px-32 xxl:px-64 xl:mb-32">
          <div className="text-center mb-10 lg:mb-0">
            <div className="mx-4 xl:mx-8 py-2 rounded-lg">
              <h5 className="text-neutrals-600 text-xl xxl:text-2xl ">
                {client.title}
              </h5>
              <h4 className="text-neutrals-800 text-2xl xxl:text-3xl">
                {client.text}
              </h4>
            </div>
          </div>

          <div className="text-center mb-10 lg:mb-0">
            <div className=" mx-4 xl:mx-8 py-2 rounded-lg ">
              <h5 className="text-neutrals-600 text-xl xxl:text-2xl">
                {location.title}
              </h5>
              <h4 className="text-neutrals-800 text-2xl xxl:text-3xl">
                {location.text}
              </h4>
            </div>
          </div>
          <div className="text-center mb-10 lg:mb-0">
            <div className=" mx-4 xl:mx-8 py-2 rounded-lg ">
              <h5 className="text-neutrals-600 text-xl xxl:text-2xl">
                {period.title}
              </h5>
              <h4 className="text-neutrals-800 text-2xl xxl:text-3xl">
                {period.text}
              </h4>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProjectDescription
