import React from "react"

import Image from "../Image"

const Content = ({ content, className }) => {
  return (
    <div className={className}>
      {content.src.ext === ".mp4" ? (
        <video
          key={`${process.env.GATSBY_API_URL}${content.src.url}`}
          className="object-cover  w-full rounded-lg shadow focus:outline-none"
          playsInline={true}
          autoPlay
          controls
          loop
          muted
          src={`${process.env.GATSBY_API_URL}${content.src.url}`}
          type="video/mp4"
        ></video>
      ) : (
        <Image
          key={content.src.url}
          className="rounded-lg object-cover w-full h-full overflow-hidden shadow"
          src={content.src}
          alt={content.alt}
        />
      )}
    </div>
  )
}

export default Content
