import React, { useState, useEffect, useContext, useRef } from "react"
import ServicesAccordion from "./Accordion"

import SiteConfig from "../../context/SiteConfig"
import Img from "../Image"
import SectionWithImage from "../SectionWithImage"

interface IService {
  id: string
  name: string
  text: string
}

const Services = ({ title, text, image, services }) => {
  return (
    <section className="pt-6 xl:pt-16">
      <SectionWithImage title={title} text={text} image={image} />

      <aside className="container mx-auto py-6 lg:px-10 xl:px-12 xl:py-16 xxl:px-20">
        <div className=" m-auto xl:max-w-5xl">
          <ServicesAccordion data={services} oneOpenAtTime />
        </div>
      </aside>
    </section>
  )
}

export default Services

export { IService }
