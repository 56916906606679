import React from "react"

import Employee from "./Employee"

const Employees = ({ moreInfo, employees }) => {
  return (
    <section className="container px-4 mx-auto py-6 md:px-6 lg:px-10 xl:px-12 xl:py-16 xxl:px-20">
      <div className="grid grid-cols-1 row-gap-10 md:grid-cols-1 xl:grid-cols-2 gap-4  xl:gap-12 xxl:gap-16 xxl:grid-cols-3">
        {employees
          .sort((employee, employee2) =>
            employee.name.localeCompare(employee2.name)
          )
          .map((employee, index) => (
            <>
              <Employee employee={employee} moreInfo={moreInfo} />
              {index !== employees.length - 1 && (
                <div
                  key={`spacer-${index}`}
                  className="my-6 w-full h-1 rounded-full bg-neutrals-300 opacity-75 lg:hidden"
                />
              )}
            </>
          ))}
      </div>
    </section>
  )
}

export default Employees
