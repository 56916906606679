import React, { useContext } from "react"
import DOMPurify from "dompurify"
import { motion, AnimatePresence } from "framer-motion"

import SiteConfigContext from "../../context/SiteConfig"
import { IPolicie } from "./Policie"
import Icons, { Icon } from "../Icons"

interface PropsAcccordionHeader {
  policie: IPolicie
  isOpen: boolean
  setActivePolicie: (newPolicieId: string) => void
}

interface PropsAccordionData {
  data: string
}

interface PropsPoliciesAccordion {
  policie: IPolicie
  isOpen: boolean
  setActivePolicie: (newPolicieId: string) => void
}

const AcccordionHeader = ({ policie, isOpen, setActivePolicie }: PropsAcccordionHeader) => {
  const onClickHeader = (): void => {
    setActivePolicie(isOpen ? null : policie.id)
  }

  return (
    <header
      className={`flex justify-between items-center p-4 bg-white cursor-pointer ${
        isOpen ? "rounded-t-lg" : "rounded-lg"
      }`}
      onClick={onClickHeader}
    >
      <h2 className="text-xl font-medium text-neutrals-800">{policie.name}</h2>
      <a>
        <Icons className="w-5 h-5 text-neutrals-800 fill-current" icon={isOpen ? Icon.arrowUp : Icon.arrowDown} />
      </a>
    </header>
  )
}

const AccordionData = ({ data }: PropsAccordionData) => {
  const { headerHeight } = useContext(SiteConfigContext)

  return (
    <motion.div
      // Start animation
      key="content"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        open: { opacity: 1, height: "auto", paddingBottom: "0.1px" },
        collapsed: { opacity: 0, height: 0 }
      }}
      transition={{ duration: 0.2 }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(data)
        }}
        className="markdown bg-white p-4 mb-3 rounded-b-lg shadow-inner overflow-y-auto"
        style={{
          maxHeight: "75vh",
          height: "100%"
        }}
      />
    </motion.div>
  )
}

const PoliciesAccordion = ({ policie, isOpen, setActivePolicie }: PropsPoliciesAccordion) => {
  return (
    <article className="my-12 mx-6 rounded-lg shadow-lg bg-white">
      <AcccordionHeader policie={policie} isOpen={isOpen} setActivePolicie={setActivePolicie} />

      <AnimatePresence initial={false}>{isOpen && <AccordionData data={policie.policie} />}</AnimatePresence>
    </article>
  )
}

export default PoliciesAccordion
