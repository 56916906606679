import React from "react"

import Hero from "../Hero"
import SectionWithImage from "../SectionWithImage"
import Banner from "../Banner"
import ListOfServices from "../ListOfServices"
import ListOfPolicies from "../ListOfPolicies"
import Map from "../Map"
import OurStory from "../OurStory"
import Employees from "../Employees"
import Projects from "../Projects"
import Services from "../Services"
import ImageSlider from "../ImageSlider"
import Policies from "../Policies"
import SEO from "../seo"
import Project from "../Project"
import ImagesLinks from "../ImagesLinks"
import AvailableJobs from "../AvailableJobs"

const components = {
  hero: Hero,
  sectionWithImage: SectionWithImage,
  banner: Banner,
  listOfServices: ListOfServices,
  listOfPolicies: ListOfPolicies,
  map: Map,
  ourStory: OurStory,
  employees: Employees,
  Services: Services,
  imagesSlider: ImageSlider,
  policies: Policies,
  projects: Projects,
  project: Project,
  SEO: SEO,
  imagesLinks: ImagesLinks,
  availableJobs: AvailableJobs,
}

const getComponent = (block, location) => {
  if (typeof components[block.component] !== "undefined") {
    return React.createElement(components[block.component], {
      key: `${block.component}_${block.id}`,
      ...block,
      pageLocation: location,
    })
  }
}

export default getComponent
