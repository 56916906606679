import React, { useContext, useState, useEffect, useRef } from "react"

import ImgWrapper from "./ImgWrapper"

import InfoBox from "./InfoBox"
import SiteConfig from "../../context/SiteConfig"
import { callbackify } from "util"

const HomePageHero = ({ contents, infoBoxes }) => {
  const { md, xl } = useContext(SiteConfig)

  return (
    <div className="relative mb-6 lg:my-6 xl:my-16">
      <div className="lg:container  md:px-6 lg:px-10 xl:px-12 mx-auto xxl:px-20">
        <div
          className="overflow-hidden h-full  m-auto "
          style={{ maxHeight: "83vh", maxWidth: 1900 }}
        >
          <ImgWrapper content={contents} />
        </div>
      </div>
      <div className="" style={{ marginTop: "-5%" }}>
        <div className="w-full pl-4 md:pl-innerWidth/4">
          <InfoBox infoBoxes={infoBoxes} />
        </div>
      </div>
    </div>
  )
}

export default HomePageHero
