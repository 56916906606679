import React, { useEffect, useState } from "react"

const StaffMoreInfo = ({ moreInfo }) => {
  const [index, setIndex] = useState(0)

  const changeIndex = ({ target }) => {
    const text = target.innerHTML

    for (const key in moreInfo) {
      if (moreInfo[key].title === text) {
        if (moreInfo[key].items.length === 0) return
      }
    }

    setIndex(target.id)
  }

  useEffect(() => {
    if (moreInfo[Object.keys(moreInfo)[0]].items.length !== 0) {
      setIndex(0)
    } else if (moreInfo[Object.keys(moreInfo)[1]].items.length !== 0) {
      setIndex(1)
    } else if (moreInfo[Object.keys(moreInfo)[2]].items.length !== 0) {
      setIndex(2)
    }
  }, [])

  return (
    <div>
      <div className="flex justify-around">
        {Object.keys(moreInfo).map((key, i) => (
          <div
            key={`i${moreInfo[key].title}`}
            className={` text-gray-900  px-4 py-2   rounded-lg select-none  ${
              i == index
                ? "shadow-inner bg-primary-400"
                : "shadow-md bg-primary-600"
            }
              ${
                moreInfo[key].items.length === 0
                  ? " opacity-25"
                  : " cursor-pointer"
              }
            `}
            id={i}
            onClick={changeIndex}
          >
            {moreInfo[key].title}
          </div>
        ))}
      </div>
      <div className="mt-8 text-neutrals-800 ">
        <ul className="overflow-y-auto  h-40 xl:h-56 ">
          {moreInfo[Object.keys(moreInfo)[index]].items.map((item, i) => (
            <li
              className={`text-neutrals-900 ${
                i === moreInfo[Object.keys(moreInfo)[index]].items.length - 1
                  ? " "
                  : "mb-2"
              }`}
              key={`i${item}`}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default StaffMoreInfo
