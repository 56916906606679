import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"

import SiteConfig from "../../context/SiteConfig"

import Icons, { Icon } from "../Icons"
import { isObject } from "util"

interface item {
  title: string
  to: string
}

interface Props {
  title: string
  items: Array<item>
  link?: any
}

const ListOfLink = ({ title, items, link, services }: Props) => {
  const { activeLanguage } = useContext(SiteConfig)

  const getTo = () => {
    let tempTo = ""
    if (services) {
      tempTo += "/services"
    } else {
      tempTo += "/company/policies"
    }

    if (activeLanguage === "is") return tempTo

    return `/${activeLanguage}${tempTo}`
  }

  return (
    <section className=" lg:py-10 xl:py-16 px-6 lg:px-16 xl:px-32 xxl:px-64 ">
      <h2 className="text-neutrals-600 pb-8 lg:text-xl">{title}</h2>
      <div className="mb-8">
        {items.map(item => (
          <Link
            className="flex items-center mb-6"
            to={getTo()}
            state={{ scrollTo: item.name }}
            key={item.name}
          >
            <Icons
              className="w-4 h-4 text-neutrals-500 fill-current mr-2"
              icon={Icon.arrowRight}
            />
            <h3
              className="text-2xl text-neutrals-700 xl:text-3xl"
              style={{ fontFamily: "'interstate', sans-serif" }}
            >
              {item.name}
            </h3>
          </Link>
        ))}
      </div>
      {isObject(link) && (
        <div className="flex justify-end">
          <Link
            className=""
            to={`${
              activeLanguage === "is" ? link.to : `/${activeLanguage}${link.to}`
            }`}
          >
            <h3
              className=" text-neutrals-700 lg:text-xl "
              style={{ fontFamily: "'interstate', sans-serif" }}
            >
              {link.title}
            </h3>
          </Link>
        </div>
      )}
    </section>
  )
}

export default ListOfLink
