import React, { useState, useContext, useRef, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import Obfuscate from "react-obfuscate"

import SiteConfig from "../../context/SiteConfig"

import Icons, { Icon } from "../Icons"
import StaffMoreInfo from "./StaffMoreInfo"
import StaffMoreInfoMobile from "./StaffMoreInfoMobile"
import Img from "../Image"

const Staff = ({ employee, moreInfo }) => {
  const { md, xl, activeLanguage } = useContext(SiteConfig)

  const [isOpen, setIsOpen] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [layoutTransition, setLayoutTransition] = useState(false)
  const [cardSize, setCardSize] = useState({
    height: 462.33,
    width: 1024,
  })

  const onClick = () => {
    setIsActive(true)
    setIsOpen(!isOpen)
  }

  const onClickClose = () => {
    setIsOpen(false)
    setIsActive(false)
  }

  const changeCardSize = () => {
    if (xl) {
      const temp = {
        height: 462.33,
        width: 1024,
      }
      if (temp.height === cardSize.height) return
      setCardSize(temp)
    }

    const temp = {
      height: 346.7475,
      width: 768,
    }

    if (temp.height === cardSize.height) return
    setCardSize(temp)
  }

  useEffect(() => {
    setTimeout(() => {
      setLayoutTransition(true)
    }, 500)

    changeCardSize()
  }, [])

  useEffect(() => {
    changeCardSize()
  }, [innerWidth])

  return (
    <>
      {md ? (
        <>
          <motion.div
            className={`fixed ${
              isOpen
                ? " z-40  inset-0  w-full h-screen overflow-hidden"
                : " z-0"
            }`}
            animate={{ opacity: isOpen ? 1 : 0 }}
            style={{ background: "rgba(0,0,0,0.8)" }}
            onClick={() => (isOpen ? setIsOpen(null) : "")}
            key={`bg ${employee.name}`}
          ></motion.div>

          {isOpen && <div key={`s ${employee.name}`}></div>}

          <motion.div
            key={employee.name}
            layout={layoutTransition}
            transition={{
              damping: 200,
              duration: 0.5,
              stiffness: 200,
            }}
            className={`${isOpen ? "fixed w-full max-w-5xl m-auto" : ""} ${
              isActive ? "z-50" : ""
            }`}
            onLayoutAnimationComplete={() => {
              if (!isOpen) {
                setIsActive(false)
              }
            }}
            style={{
              top: `${innerHeight / 2 - cardSize.height / 2}px`,
              left: `${innerWidth / 2 - cardSize.width / 2}px`,
              right: `${innerWidth / 2 - cardSize.width / 2}px`,
              width: `${isOpen ? `${cardSize.width}px` : "auto"}`,
              height: `${isOpen ? `${cardSize.height}px` : "auto"}`,
            }}
          >
            <div
              className={`  ${
                isOpen
                  ? " grid grid-cols-3 gap-2 w-full "
                  : "w-full h-full  flex  max-w-2xl m-auto"
              } `}
            >
              <div
                className={`h-full   md:max-w-sm ${
                  isOpen
                    ? "w-full max-w-sm col-span-1"
                    : "w-56 mb-6 md:mb-0 col-span-1"
                }`}
              >
                <div
                  className={`h-full object-cover m-auto ${
                    isOpen ? "w-full max-w-xl" : "w-56"
                  }`}
                >
                  <Img
                    maxWidth={500}
                    className={`h-full w-full rounded-md shadow-md object-cover object-center    `}
                    src={employee.image.src}
                    alt={employee.image.alt}
                  />
                </div>
              </div>
              <div
                className={` relative  md:bg-neutrals-100 md:w-full md:rounded-r-md md:shadow-md md:py-6 md:px-6 ${
                  isOpen
                    ? "h-full p-6 rounded-md col-span-2 shadow-md md:h-full md:my-0"
                    : " col-span-2 md:my-4"
                }`}
                style={{}}
              >
                <div
                  className={`${
                    isOpen ? " flex justify-between items-center" : ""
                  }`}
                >
                  <h2
                    className={`text-xl text-neutrals-900 ${
                      isOpen ? "md:text-2xl" : ""
                    }`}
                  >
                    {employee.name}
                  </h2>
                  <div
                    className={`text-neutrals-900 fill-current w-10 hidden  ${
                      isOpen ? "md:block cursor-pointer" : ""
                    }`}
                    onClick={onClick}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
                      <path d="M3.93 18.07A9.9995 9.9995 0 011.622 7.0905 10 10 0 113.93 18.07zm1.41-1.41a8.0046 8.0046 0 0011.3201 0 8.0045 8.0045 0 10-11.32 0zM12.4102 11l2.42 2.41a1.0036 1.0036 0 01.2941.71 1.0038 1.0038 0 01-.6199.9277 1.005 1.005 0 01-.7685 0 1.0035 1.0035 0 01-.3257-.2177l-2.41-2.43-2.41 2.42a1.0039 1.0039 0 01-1.7141-.71 1.004 1.004 0 01.294-.71L9.6 11 7.18 8.59A1.0041 1.0041 0 118.6 7.17l2.4001 2.43 2.41-2.42a1.0037 1.0037 0 011.0942-.2176A1.0041 1.0041 0 0114.8301 8.6l-2.43 2.4h.01z" />
                    </svg>
                  </div>
                </div>
                <h4 className="text-neutrals-700 ml-4">{employee.jobTitle}</h4>
                <div className={`mt-8 md:mt-4 ${isOpen ? " hidden" : ""}`}>
                  {employee.phone && (
                    <div className="flex mb-2">
                      <Icons
                        className="w-6 h-6 items-center text-neutrals-700 fill-current"
                        icon={Icon.gsm}
                      />
                      <div className="pl-2 text-lg text-neutrals-800">
                        <Obfuscate
                          tel={
                            activeLanguage == "is"
                              ? employee.phone
                              : `+354 ${employee.phone}`
                          }
                        />
                      </div>
                    </div>
                  )}

                  {employee.officePhone && (
                    <div className="flex mb-2">
                      <Icons
                        className="w-6 h-6 text-neutrals-600 fill-current"
                        icon={Icon.phone}
                      />
                      <div className="pl-2 text-lg text-neutrals-800">
                        <Obfuscate
                          tel={
                            activeLanguage == "is"
                              ? employee.officePhone
                              : `+354 ${employee.officePhone}`
                          }
                        />
                      </div>
                    </div>
                  )}

                  {employee.email && (
                    <div className="flex items-center mb-2">
                      <Icons
                        className="w-6 h-6 text-neutrals-600 fill-current"
                        icon={Icon.mail}
                      />

                      <h3 className="pl-2 text-lg text-neutrals-800">
                        <Obfuscate email={employee.email} />
                      </h3>
                    </div>
                  )}
                </div>

                {isOpen ? (
                  <div className={`${isOpen ? "mt-6" : ""}`}>
                    <StaffMoreInfo moreInfo={employee.moreInfo} />
                  </div>
                ) : (
                  <div className=" absolute bottom-0 right-0 w-full pb-4 pr-6 flex justify-end">
                    <div
                      className="text-neutrals-600 cursor-pointer"
                      onClick={onClick}
                    >
                      {moreInfo}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </>
      ) : (
        <div key={employee.name} className="md:grid md:grid-cols-3 relative">
          <div className="w-48 m-auto mb-6 md:col-span-1">
            <Img
              maxWidth={500}
              src={employee.image.src}
              alt={employee.image.alt}
              className="rounded-md"
            />
          </div>
          <div className="md:col-span-2 max-w-md m-auto md:max-w-none md:m-0">
            <h2 className="text-2xl text-neutrals-800">{employee.name}</h2>
            <h4 className="text-neutrals-700 ml-4 -mt-2">
              {employee.jobTitle}
            </h4>
            <div className="mt-10 md:mt-6">
              {employee.phone && (
                <div className="flex mb-2">
                  <Icons
                    className="w-6 h-6 text-neutrals-600 fill-current"
                    icon={Icon.gsm}
                  />
                  <h3 className="pl-2 text-lg text-neutrals-800">
                    <Obfuscate
                      tel={
                        activeLanguage == "is"
                          ? employee.phone
                          : `+354 ${employee.phone}`
                      }
                    />
                  </h3>
                </div>
              )}
              {employee.officePhone && (
                <div className="flex mb-2">
                  <Icons
                    className="w-6 h-6 text-neutrals-600 fill-current"
                    icon={Icon.phone}
                  />
                  <h3 className="pl-2 text-lg text-neutrals-800">
                    <Obfuscate
                      tel={
                        activeLanguage == "is"
                          ? employee.officePhone
                          : `+354 ${employee.officePhone}`
                      }
                    />
                  </h3>
                </div>
              )}
              {employee.email && (
                <div className="flex mb-2">
                  <Icons
                    className="w-6  h-6 text-neutrals-600 fill-current"
                    icon={Icon.mail}
                  />
                  <h3 className="pl-2 text-lg text-neutrals-800">
                    <Obfuscate email={employee.email} />
                  </h3>
                </div>
              )}
            </div>
            <div className="flex justify-end md:absolute md:bottom-0 md:right-0 md:p-6">
              <div className="text-neutrals-600" onClick={onClick}>
                {moreInfo}
              </div>
            </div>
          </div>
        </div>
      )}

      {!md && (
        <StaffMoreInfoMobile
          employee={employee}
          isOpen={isOpen}
          onClickClose={onClickClose}
        />
      )}
    </>
  )
}

export default Staff
