import React, { useContext } from "react"
import { Link } from "gatsby"

import SiteConfig from "../../context/SiteConfig"
import ListOfLink from "../ListOfLink"

import Icons, { Icon } from "../Icons"

const ListOfPolicies = ({ title, policies, link }) => {
  const { activeLanguage } = useContext(SiteConfig)

  return (
    <section className=" container px-4 mx-auto py-6 md:px-6 lg:px-10 xl:px-12 xl:py-16 xxl:px-20 ">
      <h2 className="text-neutrals-600 pb-6 lg:text-2xl">{title}</h2>
      <div className="flex flex-wrap">
        {policies.map((policie, index) => (
          <div
            key={policie.id}
            className={`m-4 bg-white shadow rounded-lg w-full lg:w-auto `}
          >
            {!policie.pdf ? (
              <Link
                className="flex items-center justify-between p-4"
                state={{ scrollTo: policie.name }}
                key={policie.name}
                to={`${
                  activeLanguage === "is" ? "" : `/${activeLanguage}`
                }/policies`}
              >
                <h3
                  className="text-xl text-neutrals-700  mr-4 whitespace-nowrap"
                  style={{ fontFamily: "'interstate', sans-serif" }}
                >
                  {policie.name}
                </h3>

                <Icons
                  className="w-6 text-neutrals-600 stroke-current "
                  icon={Icon.link}
                />
              </Link>
            ) : (
              <a
                className="flex items-center justify-between p-4 "
                key={policie.name}
                href={`/uploads${policie.policie.src.url}`}
                target="_blank"
              >
                <h3
                  className="text-xl text-neutrals-700  mr-4 whitespace-nowrap"
                  style={{ fontFamily: "'interstate', sans-serif" }}
                >
                  {policie.name}
                </h3>
                <Icons
                  className="w-8 text-neutrals-600 fill-current"
                  icon={Icon.pdf}
                />
              </a>
            )}
          </div>
        ))}
      </div>
    </section>
  )
}

export default ListOfPolicies
