import React, { useContext } from "react"
import DOMPurify from "dompurify"

import Img from "../Image"
import Icons, { Icon } from "../../components/Icons"
import { Link } from "gatsby"
import SiteConfigContext from "../../context/SiteConfig"

const ProjectCardTest = ({
  project,
  activeLanguage,
  link,
  onClickExitPopUp,
  projectPopUpTitles,
  height,
  activeProject,
}) => {
  const { lg } = useContext(SiteConfigContext)

  return (
    <section
      key={project.name[activeLanguage]}
      className="  font-sans relative "
      style={{
        maxWidth: lg ? `${innerWidth * 0.6}px` : `${innerWidth * 0.8}px`,
      }}
      onClick={e => e.stopPropagation()}
    >
      <article className=" max-w-sm shadow-lg">
        <div className=" bg-white rounded-b-md ">
          <div className=" flex justify-between items-center rounded-t-md  bg-neutrals-800 p-4 z-30">
            <h2
              className={`text-xl font text-white whitespace-nowrap`}
              style={{
                width: "20rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {project.name[activeLanguage]}
            </h2>

            <div className="cursor-pointer relative z-30" onClick={onClickExitPopUp}>
              <Icons
                className="w-6 h-6 m-1 text-bold text-neutrals-200 fill-current"
                icon={Icon.x}
              />
            </div>
          </div>

          <div
            className="overflow-y-auto  p-4 shadow-inner"
            style={{ maxHeight: `${height}px` }}
          >
            {project.card && (
              <div className="flex mx-auto mb-4 w-full " style={{}}>
                <Img
                  maxWidth={500}
                  className="h-auto w-full max-h-64  rounded"
                  src={project.card.src}
                  alt={project.card.alt[activeLanguage]}
                />
              </div>
            )}

            {project.text && (
              <div className="mb-4 text-neutrals-800 text-base hidden xl:block">
                <div
                  className="overflow-hidden"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 6,
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(project.text[activeLanguage]),
                  }}
                ></div>
              </div>
            )}

            {project.period && (
              <div className=" text-base mb-2 grid gap-4 grid-cols-3 ">
                <h3 className="mb-1 text-neutrals-700 font-bold">
                  {projectPopUpTitles.periodTitle[activeLanguage]}
                </h3>
                <h3 className="col-span-2  text-neutrals-900">
                  {project.period}
                </h3>
              </div>
            )}

            {project.location && (
              <div className=" text-base mb-2 grid gap-4 grid-cols-3">
                <h3 className="mb-1  text-neutrals-700  font-bold">
                  {projectPopUpTitles.locationTitle[activeLanguage]}
                </h3>
                <h3 className="col-span-2   text-neutrals-900">
                  {project.location[activeLanguage]}
                </h3>
              </div>
            )}

            {project.client && (
              <div className=" text-base mb-2 grid gap-4 grid-cols-3">
                <h3 className="mb-1 text-neutrals-700 font-bold">
                  {projectPopUpTitles.clientTitle[activeLanguage]}
                </h3>
                <h3 className=" col-span-2 text-neutrals-900">
                  {project.client[activeLanguage]}
                </h3>
              </div>
            )}

            {project.ourRoles && project.ourRoles.length != 0 && (
              <div className="pb-1 mt-4 pt-4 text-neutrals-700 border-t-2 border-neutrals-200  ">
                <h3 className="text-neutrals-700 mb-2 text-lg  font-bold">
                  {projectPopUpTitles.ourRolesTitle[activeLanguage]}
                </h3>
                <ul className="">
                  {project.ourRoles.map(ourRole => (
                    <li
                      key={ourRole[activeLanguage]}
                      className="ml-4 my-1 text-neutrals-900 text-base"
                    >
                      {ourRole[activeLanguage]}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className={!project.link && project.ourRoles ? "pb-4" : ""}>
              {" "}
            </div>

            {project.isLink && activeProject !== project.slug && (
              <div className="flex justify-end uppercase mt-2 px-4 pb-4">
                <Link
                  to={`${activeLanguage === "is" ? "" : activeLanguage}${
                    link.to
                  }/${project.slug}`}
                >
                  {link.title[activeLanguage]}
                </Link>
              </div>
            )}
          </div>
        </div>
      </article>
    </section>
  )
}

export default ProjectCardTest
