import React, { useContext, useEffect } from "react"
import SiteConfigContext from "../../context/SiteConfig"

import { IPolicie } from "./Policie"

interface IPoliciesMenu {
  policies: [IPolicie]
  activePolicie: string
  setActivePolicie: (newPolicieId: string) => void
}

interface IPolicieMenuItem {
  policie: IPolicie
  isOpen: boolean
  setActivePolicie: (newPolicieId: string) => void
}

const PoliciesMenu = ({ policies, activePolicie, setActivePolicie }: IPoliciesMenu) => {
  const { activeLanguage } = useContext(SiteConfigContext)

  return (
    <div className="flex rounded-lg shadow-lg bg-white self-start flex-none ">
      <div className=" flex-none w-1 bg-primary-800 my-4 rounded-full" />

      <section className="p-6" style={{ paddingLeft: "23px" }}>
        <header className="mb-2">
          <h2 className="text-sm text-neutrals-700 font-medium">
            {activeLanguage == "is" ? "Okkar stefnur" : "Our policies"}
          </h2>
        </header>

        <nav>
          <ul className="">
            {policies.map(policie => (
              <PoliciesMenuItem
                policie={policie}
                isOpen={policie.id == activePolicie}
                setActivePolicie={setActivePolicie}
              />
            ))}
          </ul>
        </nav>
      </section>
    </div>
  )
}

const PoliciesMenuItem = ({ policie, isOpen, setActivePolicie }: IPolicieMenuItem) => {
  const onChangeActivePolicie = (event: React.MouseEvent<Element, MouseEvent>): void => {
    event.preventDefault()

    setActivePolicie(policie.id)
  }

  return (
    <li>
      <a
        onClick={onChangeActivePolicie}
        className={` inline-block text-neutrals-800 font-medium rounded-lg p-2 ${
          isOpen ? "p-2 bg-neutrals-100 shadow-sm cursor-default" : " cursor-pointer"
        }`}
      >
        {policie.name}
      </a>
    </li>
  )
}

export default PoliciesMenu
