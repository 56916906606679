import React, { useContext, useRef, useEffect, useState } from "react"
import DOMPurify from "dompurify"

import SiteConfig from "../../context/SiteConfig"

import Image from "../Image"

const SectionWithImage = ({ title, text, image }) => {
  const { lg, xl } = useContext(SiteConfig)

  const [textWrapperRef, setTextWrapperRef] = useState(null)

  return (
    <section className="relative container mx-auto px-4 py-6  md:px-6 lg:px-10 xl:px-12 xl:py-16 xxl:px-20" style={{}}>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <aside className="">
          <div className="relative">
            <Image
              allowZoom={true}
              className="rounded-lg shadow-md w-full"
              maxWidth={600}
              src={image.src}
              alt={image.alt}
            />
            <div className="absolute inset-0" style={{ backgroundColor: "rgba(1,77,64, 0.2)" }} />
          </div>
        </aside>
        <div className="mt-auto " ref={ref => setTextWrapperRef(ref)}>
          <div className=" bg-white shadow rounded-md border-primary-700 flex self-start ">
            <div className="w-1 bg-primary-800 rounded-full my-4 flex-none" />

            <article className="p-6" style={{ paddingLeft: "23px" }}>
              <h2 className="text-xl md:text-2xl text-neutrals-800 mb-4 font-medium">{title}</h2>
              <div
                className="markdown text-neutrals-900"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(text)
                }}
              />
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionWithImage
