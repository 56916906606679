import React, { useState, useEffect, useRef } from "react"
import getComponent from "./components/getComponent"

const app = ({ pageContext, location }) => {
  const { pageData } = pageContext

  if (typeof window === `undefined`) {
    return <></>
  }

  return <>{pageData.map(block => getComponent(block, location))}</>
}

export default app
