import React, { useState } from "react"
import { motion, AnimatePresence, animationControls } from "framer-motion"

import Icons, { Icon } from "../Icons"

const variants = {
  closed: {
    height: 0,
  },
  open: {
    height: "auto",
  },
}

const Accordion = ({ title, accordionData, onOpenAtTime, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [animationEnded, setAnimationEnded] = useState(true)

  const onClick = () => {
    setIsOpen(!isOpen)
    setAnimationEnded(false)
  }

  return (
    <div key={title} className={` ${accordionData ? "" : "mb-6"} `}>
      <div
        className={`flex justify-between items-center p-4 mx-2    cursor-pointer  ${
          !accordionData
            ? `bg-white shadow select-none mt-2 ${
                isOpen || !animationEnded
                  ? " rounded-t-lg border-b border-neutrals-200 "
                  : "rounded-lg"
              }`
            : ""
        } `}
        onClick={onClick}
      >
        <h3 className="text-lg text-neutrals-900 ">{title}</h3>
        <Icons
          className="w-4 h-4 text-neutrals-800 fill-current"
          icon={isOpen ? Icon.arrowUp : Icon.arrowDown}
        />
      </div>
      <AnimatePresence onExitComplete={() => setAnimationEnded(true)}>
        {isOpen && (
          <motion.article
            key={`${title}-data`}
            className="overflow-hidden "
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants}
            transition={{
              damping: 300,
            }}
          >
            {accordionData ? (
              <ul className="pl-4 py-4 border-neutrals-300 border-l-2 mx-2 mt-b">
                {accordionData.map((info, index) => (
                  <li
                    className={` text-neutrals-700 ${
                      index !== accordionData.length - 1 ? "mb-4" : ""
                    }`}
                  >
                    {info}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="bg-white p-4 rounded-b-lg shadow mx-2 mb-2">
                {children}
              </div>
            )}
          </motion.article>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Accordion
