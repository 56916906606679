import React, { useState } from "react"
import AliceCarousel from "react-alice-carousel"

import Icons, { Icon } from "../Icons"

import Image from "../Image"

const handleDragStart = e => e.preventDefault()

const Gallery = ({ images }) => {
  return (
    <div>
      <AliceCarousel
        disableDotsControls
        renderNextButton={isDisabled => (
          <div className="relative bg-neutrals-100 inline-block p-4  rounded-full shadow-lg z-10 opacity-70 cursor-pointer hover:shadow-inner">
            <Icons className="w-6 h-6" icon={Icon.arrowRight} />
          </div>
        )}
        renderPrevButton={isDisabled => (
          <div className="relative bg-neutrals-100 inline-block p-4  rounded-full shadow-lg z-10 opacity-70 cursor-pointer  hover:shadow-inner">
            <Icons className="w-6 h-6" icon={Icon.arrowLeft} />
          </div>
        )}
        responsive={{
          0: {
            items: 1,
          },
          1024: {
            items: 2,
          },
          1536: {
            items: 3,
          },
        }}
      >
        {images.map((image, index) => (
          <div
            key={`${index}${image.alt}`}
            className="p-4 m-auto"
            style={{ maxWidth: "600px", height: "400px" }}
          >
            <Image
              maxWidth={700}
              className="h-full w-full object-cover rounded-lg shadow-lg"
              src={image.src}
              alt={image.alt}
            />
          </div>
        ))}
      </AliceCarousel>
    </div>
  )
}

export default Gallery
