import React from "react"

import ProjectCard from "./ProjectCard"

const Projects = ({ projects }) => {
  return (
    <div className="container px-4 mx-auto py-6 md:px-6 lg:px-10 xl:px-12 xl:py-16 xxl:px-20">
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-6 lg:gap-8 xl:gap-10 xxl:gap-14 py-4">
        {projects.map(project => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  )
}

export default Projects
