import React from "react"

import ProjectDescription from "./ProjectDescription"
import ImageSlider from "../ImageSlider"

const Project = ({
  component,
  period,
  slug,
  name,
  description,
  client,
  location,
  ourRoles,
  coordinates,
  images,
  moreImages,
}) => {
  return (
    <section className="container mx-auto px-4 my-6  md:px-6 lg:px-10 xl:px-12 xl:my-16 xxl:px-20">
      <ProjectDescription
        client={client}
        description={description}
        location={location}
        name={name}
        ourRoles={ourRoles}
        period={period}
      />
      <ImageSlider images={moreImages.images} />
    </section>
  )
}

export default Project
