import React from "react"

import ListOfLink from "../ListOfLink"

const ListOfServices = ({ title, services, link }) => {
  return (
    <ListOfLink title={title} items={services} link={link} services={true} />
  )
}

export default ListOfServices
