import React from "react"

import AvailableJob from "./AvailableJob"

const AvailableJobs = ({ title, availableJobs, openTimeTitle }) => {
  return (
    <div className="px-4 max-w-4xl m-auto py-6 xl:py-16">
      <div className="mb-6 xl:mb-16 inline-block pl-4 py-4 pr-6 bg-neutrals-800 border-l-8 border-primary-700 shadow-md rounded ">
        <h1 className="text-xl xl:text-3xl text-neutrals-200 select-none">
          {title}
        </h1>
      </div>

      <div className="">
        {availableJobs.map(availableJob => (
          <AvailableJob
            key={availableJob.title}
            availableJob={availableJob}
            publishedTimeTitle={openTimeTitle}
          />
        ))}
      </div>
    </div>
  )
}

export default AvailableJobs
