import React, { useState, useRef, useEffect, useContext } from "react"
import { motion, AnimatePresence } from "framer-motion"
import DOMPurify from "dompurify"

import Content from "./Content"
import SiteConfigContext from "../../context/SiteConfig"

const OurStory = ({ title, text, content }) => {
  return (
    <section className="container px-4 mx-auto py-6 md:px-6 lg:px-10 xl:px-12 xl:py-16 xxl:px-20 ">
      <div className=" grid grid-col-1 gap-6 xl:grid-cols-2 ">
        <aside className="w-full">
          <Content className="w-full" content={content} />
        </aside>
        <div className="mt-auto ">
          <div className=" bg-white shadow rounded-md border-primary-700 flex self-start ">
            <div className="w-1 bg-primary-800 rounded-full my-4 flex-none" />

            <article className="p-6" style={{ paddingLeft: "23px" }}>
              <h2 className="text-xl md:text-2xl text-neutrals-800 mb-4 font-medium">{title}</h2>
              <div
                className="markdown text-neutrals-900"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(text)
                }}
              />
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurStory
