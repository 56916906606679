import React, { useContext, useState, useEffect } from "react"

import { motion, AnimatePresence } from "framer-motion"

import SiteConfig from "../../context/SiteConfig"

import Image from "../Image"

import getRandomIndex from "../../util/getRandomIndex"

const Banner = ({ images, title }) => {
  const { headerHeight, md } = useContext(SiteConfig)

  const [imageHeight, setImageHeight] = useState(null)
  const [hasSameAspectRatio, setHasSameAspectRatio] = useState(null)

  const [activeImage, setActiveImage] = useState(null)

  const [device, setDevice] = useState(null)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (!device) return

    if (images[device].length === 1) return

    setIndex(getRandomIndex(images[device].length))
  }, [images, device])

  useEffect(() => {
    if (md) {
      setDevice("lg")
    } else {
      setDevice("default")
    }

    window.addEventListener("resize", () => {
      if (md) {
        setDevice("lg")
      } else {
        setDevice("default")
      }
    })
  })

  useEffect(() => {
    if (!images || !device) return

    const activeImage = images[device][index]
    setActiveImage(activeImage)
    const activeImageAspectRatio = Math.round(
      activeImage.src.width / activeImage.src.height
    )

    setHasSameAspectRatio(
      device == "lg"
        ? activeImageAspectRatio == 10 / 2
        : activeImageAspectRatio == 3 / 2
    )
  }, [index, device, images])

  useEffect(() => {
    if (device == "lg") {
      setImageHeight(innerWidth * (2 / 10))
    } else if (device == "default") {
      setImageHeight(innerWidth * (2 / 3))
    }
  }, [activeImage, device, index])

  if (!activeImage) return <div />

  return (
    <section className="relative w-full overflow-hidden -mt-1" style={{}}>
      <div key={index} className="w-full flex">
        <div className={`relative  mx-auto `} style={{ zIndex: 5 }}>
          <Image
            key={activeImage.src.url}
            className=" object-cover md:object-contain"
            alt={activeImage.alt}
            src={activeImage.src}
            style={{
              height: `${imageHeight}px`,
              width: "100vw",
              aspectRatio: md ? "10/2" : "3/2",
            }}
            maxWidth={innerWidth + 200}
          />
        </div>
        {!hasSameAspectRatio && (
          <Image
            key={`${activeImage.src.ur}-back-background-image`}
            className=" absolute inset-0 w-full h-full object-center object-cover z-0 opacity-40"
            alt={activeImage.alt}
            src={activeImage.src}
            style={{
              aspectRatio: md ? "10/2" : "3/2",
            }}
            maxWidth={innerWidth + 200}
          />
        )}
        <div
          className="absolute inset-0 w-full h-full bg-neutrals-1000 z-10"
          style={{ opacity: 0.5 }}
        ></div>
        <div
          className=" absolute inset-0 container px-4 md:px-6 lg:px-10 xl:px-12 mx-auto xxl:px-20"
          style={{ zIndex: 15 }}
        >
          <div className="h-full flex items-end pb-6 lg:pb-8">
            <div className="pl-4 py-4 pr-6 bg-neutrals-800 border-l-8 border-primary-700 shadow-md rounded">
              <h1 className="text-xl xl:text-3xl text-neutrals-200 select-none">
                {title}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
